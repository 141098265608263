import React from 'react';
import { NavLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core';
import { ComparelistContext } from '@/context/comparelist-num-context';
import styles from './WishlistNumStyles';

function ComparelistNum(props) {
  const { classes } = props;

  const closeAllSlides = props.closeAllSlides ? props.closeAllSlides : () => {};

  const { compareNum } = React.useContext(ComparelistContext);

  return (
    <NavLink
      to="/comparelist/"
      className={props.class}
      onClick={closeAllSlides}
    >
      Compare
      <span className={classes.numRound}>{compareNum}</span>
    </NavLink>
  );
}

export default withStyles(styles)(ComparelistNum);
