import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { withStyles, Grid, Typography, Box } from '@material-ui/core';
import moment from 'moment';
import styles from './PostCardStyles.js';

function PostCard(postProps) {
  const { classes, post, route, showDate } = postProps;

  return (
    <Grid item className={classes.catItemContainer} key={post.slug}>
      <div className={classes.hoverCard}>
        <NavLink to={`${route}/${post.slug}`} className={classes.aNone}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
            className={classes.h100}
          >
            <Grid
              item
              className={classes.productImgContainer}
              style={{ backgroundImage: `url("${post.featuredImgUrl}")` }}
            />
            <Grid
              item
              container
              direction="column"
              justify="space-between"
              alignItems="stretch"
              className={classes.flexGrow}
            >
              <Box component="div" p={3}>
                {showDate && (
                  <Typography variant="caption">
                    {moment(post.date).format('Do MMM YYYY')}
                  </Typography>
                )}
                <Box
                  fontWeight="fontWeightBold"
                  dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                />
              </Box>
            </Grid>
          </Grid>
        </NavLink>
      </div>
    </Grid>
  );
}

export default withRouter(withStyles(styles)(PostCard));
