const styles = (theme) => ({
  catItemContainer: {
    padding: '6px',
    width: '20%',

    [theme.breakpoints.down('md')]: {
      width: '33.3%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  aNone: theme.mixins.aNone,
  productItemContainer: {
    backgroundColor: theme.palette.additional.white,
  },
  hoverCard: theme.mixins.hovercard,
  h100: {
    height: '100%',
  },
  productImgContainer: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: '200px',
    maxHeight: '200px',
    overflow: 'hidden',
  },
});

export default styles;
