// import mainBanner from '@/assets/images/mainBanner.jpg';

const styles = (theme) => ({
  numRound: {
    backgroundColor: theme.palette.additional.white,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1.2em',
    width: '1.2em',
    color: theme.palette.additional.black,
    borderRadius: '50%',
    marginLeft: '.6em',
  },
});

export default styles;
