import React from 'react';

import { Box } from '@material-ui/core';
import { CategoriesContext } from '@/context/categories-context';
import { CurrentProductContextProvider } from '@/context/current-product-context';

import Breadcrumbs from '@/components/products/Breadcrumbs';
import ProductsFilter from '@/components/products/ProductsFilter';
import ProductDisplay from '@/components/products/ProductDisplay';
import ProdCatSwitch from '@/components/products/ProdCatSwitch';

function Products(props) {
  const catDataAll = React.useContext(CategoriesContext);
  let catsParentSlug = '';
  if (props.match.params.cat) {
    catsParentSlug = encodeURIComponent(props.match.params.cat);
  }

  let currentCat = {};
  if (catDataAll.load) {
    if (!catsParentSlug) {
      currentCat.id = 0;
    } else {
      currentCat = findCatDataBySlug(catDataAll.data, catsParentSlug);
    }
  }

  // function to find category id by slug
  function findCatDataBySlug(catsData = {}, catSlug = '') {
    let currentCat = {};
    for (const key in catsData) {
      if (catsData.hasOwnProperty(key)) {
        // eslint-disable-next-line
        catsData[key].forEach((item) => {
          if (item.slug === catSlug) {
            currentCat = {
              id: item.id,
              title: item.title,
              img: item.img,
              slug: item.slug,
            };
          }
        });
      }
    }
    return currentCat;
  }

  return (
    <CurrentProductContextProvider>
      <Breadcrumbs
        currentCat={props.match.params.cat}
        isShowProduct={!!props.match.params.product}
      />

      {props.match.params.product ? (
        <ProductDisplay productSlug={props.match.params.product} />
      ) : (
        <>
          {catDataAll.data[currentCat.id] && (
            <ProdCatSwitch
              catSlug={catsParentSlug || ''}
              catName={currentCat.title}
            />
          )}
          <ProductsFilter currentCat={currentCat} />
        </>
      )}
      <Box component="div" p={5} />
    </CurrentProductContextProvider>
  );
}

export default Products;
