import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { withStyles, Grid, Typography, Box } from '@material-ui/core';

import { CategoriesContext } from '@/context/categories-context';

import Loader from '@/components/Loader';
import UseDataApi from '@/hooks/UseDataApi';
import FourtyFour from '@/components/FourtyFour';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';
import styles from './CatsListStyles.js';

function CatsList(props) {
  // console.log(props.cat.slug);

  const { classes, catsParent, catsNamesStr } = props;

  // catsParent - is an ID of category

  const catDataAll = React.useContext(CategoriesContext);

  const router = useLocation();
  const { pathname } = router;
  const path = pathname.split('/')[1];

  let catUrl = 'categories';
  let productsUrl = 'products';

  const url = `${
    process.env.REACT_APP_API_BASE + process.env.REACT_APP_API_PRODUCTS
  }?cat=0`;
  const productsData = UseDataApi(url, 'historical-products');

  const catSlugs = productsData.data.map((p) => p.catTopParentId);
  const availableCats = [...new Set(catSlugs)];

  if (path.includes('historical-products')) {
    catUrl = 'historical-products-categories';
    productsUrl = 'historical-products';
  }


  let catData = [];
  if (catDataAll.load) {
    if (catsParent === 0 && path.includes('historical-products')) {
      catData = catDataAll.data[catsParent].filter((product) => availableCats.includes(product.id));
    } else if (catsParent === 0 || catsParent) {
      catData = catDataAll.data[catsParent];
    } else if (catsNamesStr) {
      let catsNamesArr = [];
      catsNamesArr = catsNamesStr.split(',');
      for (const key in catDataAll.data) {
        catsNamesArr.map((catsName) => {
          catDataAll.data[key].map((categoryData) => {
            if (categoryData.title === catsName) {
              catData.push(categoryData);
            }
            return true;
          });
          return true;
        });
      }
    }
  }

  function CatCard(props) {
    const [open, setOpen] = React.useState(false);

    return (
      <Grid item className={classes.catItemContainer}>
        <Box
          component="div"
          className={classes.hoverCard}
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
        >
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
            className={classes.h100}
          >
            <NavLink
              to={
                catDataAll.data[props.cat.id]
                  ? `/${catUrl}/${props.cat.slug}`
                  : `/${productsUrl}/${props.cat.slug}`
              }
              className={`${classes.decorationNone}`}
            >
              <Grid
                item
                className={classes.catImgContainer}
                style={{ backgroundImage: `url("${props.cat.img}")` }}
              />

              <Grid item className={classes.flexGrow}>
                <Box component="div" px={1} pt={1}>
                  <Typography variant="h4" component="span">
                    {props.cat.title}
                  </Typography>
                </Box>
              </Grid>
            </NavLink>
            {catDataAll.data[props.cat.id] ? (
              <Grid
                item
                container
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
                className={classes.catTitleContainer}
              >
                <Grid item>
                  <Box
                    component="div"
                    className={`${classes.viewAll} ${
                      open ? classes.viewAllSelected : ''
                    }`}
                  >
                    <span className={classes.darker}>{props.cat.title}</span>{' '}
                    CATEGORIES
                  </Box>
                </Grid>
                <Grid item className={classes.arrow}>
                  {open ? <span>&#9652;</span> : <span>&#9662;</span>}

                  <div
                    className={`${classes.popoverContainer} ${
                      open ? classes.popoverContainerDisplay : ''
                    }`}
                  >
                    {catDataAll.data[props.cat.id].map((subCat, index) => (
                      <NavLink
                        to={`/${
                          catDataAll.data[subCat.id] ? catUrl : productsUrl
                        }/${subCat.slug}`}
                        className={classes.subCatLink}
                        key={`subcat${index}`}
                      >
                        {subCat.title}
                      </NavLink>
                    ))}
                    <Box component="div" pt={1}>
                      <NavLink
                        to={`/${productsUrl}/${props.cat.slug}`}
                        className={`${classes.subCatLink} ${classes.viewAll}`}
                      >
                        VIEW ALL{' '}
                        <span className={classes.darker}>
                          {props.cat.title}
                        </span>{' '}
                        PRODUCTS
                      </NavLink>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <NavLink
                to={`/${productsUrl}/${props.cat.slug}`}
                className={classes.viewAll}
              >
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                  wrap="nowrap"
                  className={classes.catTitleContainer}
                >
                  <Grid item className={open ? classes.viewAllSelected : ''}>
                    VIEW{' '}
                    <span className={classes.darker}>{props.cat.title}</span>{' '}
                    PRODUCTS
                  </Grid>
                  <Grid item className={classes.arrow}>
                    <span>&#9656;</span>
                  </Grid>
                </Grid>
              </NavLink>
            )}
          </Grid>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid
      container
      className={classes.catsContainer}
      alignItems="stretch"
      justify="flex-start"
    >
      {path.includes('historical-products') && !productsData.load && <Loader />}
      {catData.length <= 0 && productsData.load && <FourtyFour msg="No category found" />}
      {catData ? (
        catData?.map((cat, index) => (
          <CatCard cat={cat} key={`categoryCard${index}`} />
        ))
      ) : (
        <Loader />
      )}
    </Grid>
  );
}

export default withRouter(withStyles(styles)(CatsList));
