import { Box, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export default function HistoricalProductsWarning() {
    const location = useLocation();
    const { pathname } = location;

    return (
      <Box px={3.5} display={pathname.includes('historical') ? 'block' : 'none'} textAlign="center" width="100%" sx={{ opacity: 0.5 }}>
        <Typography>
          You are in the HISTORICAL PRODUCTS  area of our site.
          To return to Current Products, click PRODUCTS in the main menu at the top of the screen.
        </Typography>
      </Box>
    );
}
