import React from 'react';

import { Box } from '@material-ui/core';
import { CategoriesContext } from '@/context/categories-context';

import Breadcrumbs from '@/components/products/Breadcrumbs';
import CatsList from '@/components/products/CatsList';
import PordCatSwitch from '@/components/products/ProdCatSwitch';

import FourtyFour from '@/components/FourtyFour';
import Loader from '@/components/Loader';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function Categories(props) {
  const catDataAll = React.useContext(CategoriesContext);
  const catsParentSlug = props.match.params.cat;


  let currentCat = {};
  let subCatData = null;
  if (catDataAll.load) {
    if (!catsParentSlug) {
      currentCat.id = 0;
    } else {
      currentCat = findCatDataBySlug(catDataAll.data, catsParentSlug);
    }
    subCatData = catDataAll.data[currentCat.id];
  }

  // function to find category id by slug
  function findCatDataBySlug(catsData = {}, catSlug = '') {
    let currentCat = {};
    for (const key in catsData) {
      if (catsData.hasOwnProperty(key)) {
        // eslint-disable-next-line
        catsData[key].forEach((item) => {
          if (item.slug === catSlug) {
            currentCat = {
              id: item.id,
              title: item.title,
              img: item.img,
              slug: item.slug,
            };
          }
        });
      }
    }
    return currentCat;
  }

  const router = useLocation();
  const { pathname } = router;
  const path = pathname.split('/')[1];

  return (
    <>
      <Breadcrumbs 
        currentCat={props.match.params.cat} 
        baseUrl={path.includes('historical-products') ? '/historical-products' : null} 
      />
      <PordCatSwitch
        catSlug={catsParentSlug || ''}
        catName={currentCat.title}
        urlBase={path.includes('historical-products') ? 'historical-products' : null}
        catUrlBase={path.includes('historical-products') ? 'historical-products-categories' : null}
      />
      {catDataAll.load ? (
        <>
          {subCatData ? (
            <CatsList catsParent={currentCat.id} path={path} />
          ) : (
            <FourtyFour msg="No subcategories in this category" />
          )}
        </>
      ) : (
        <Loader />
      )}
      <Box component="div" p={5} />
    </>
  );
}

export default Categories;
