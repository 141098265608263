import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { withStyles, Grid, Typography, Box } from '@material-ui/core';
import moment from 'moment';

import UseDataApi from '@/hooks/UseDataApi';
import Loader from '@/components/Loader';
import Error from '@/components/Error';
import FourtyFour from '@/components/FourtyFour';
import PostCard from '@/components/posts/PostCard';
import styles from './NewsStyles.js';

function NewsPage(props) {
  const { classes } = props;

  let postSlug = null;
  if (props.match.params.postSlug) {
    postSlug = encodeURIComponent(props.match.params.postSlug);
  }

  function DisplayOnePost() {
    const POST_API_URL = `${process.env.REACT_APP_API_BASE}/posts?slug=${postSlug}`;
    const postData = UseDataApi(POST_API_URL);
    return (
      <>
        {postData.error ? (
          <Error />
        ) : postData.load ? (
          postData.data.length > 0 ? (
            <>
              <Box px={3} pt={3}>
                <NavLink to="/news" className={classes.arrow}>
                  &#9666; Back to News &#9666;
                </NavLink>
              </Box>
              <Box p={6}>
                <Box py={2}>
                  <Typography variant="caption">
                    {moment(postData.data[0].date).format('Do MMM YYYY')}
                  </Typography>
                </Box>
                <Typography
                  variant="h2"
                  dangerouslySetInnerHTML={{
                    __html: postData.data[0].title.rendered,
                  }}
                />
                <Box
                  pt={2}
                  dangerouslySetInnerHTML={{
                    __html: postData.data[0].content.rendered,
                  }}
                />
              </Box>
              <Box px={3} pt={3}>
                <NavLink to="/news" className={classes.arrow}>
                  &#9666; Back to News &#9666;
                </NavLink>
              </Box>
            </>
          ) : (
            <FourtyFour msg="No Posts" />
          )
        ) : (
          <Loader />
        )}
      </>
    );
  }

  function DisplayCategories() {
    const POSTS_CATEGORIES_URL = `${process.env.REACT_APP_API_BASE}/categories`;
    const newsCatsData = UseDataApi(POSTS_CATEGORIES_URL);
    const [chosenCat, setChosenCat] = React.useState();
    return (
      <>
        {newsCatsData.error ? (
          <Error />
        ) : newsCatsData.load ? (
          <>
            <Box className={classes.newsHeaders}>
              {newsCatsData.data.map(
                (oneCategory, index) =>
                  oneCategory.name !== 'Uncategorized' && (
                    <Box
                      onClick={() => setChosenCat(oneCategory.id)}
                      key={`NewsCategory${index}`}
                      className={`${classes.newsHeader} ${
                        chosenCat === oneCategory.id ||
                        (!chosenCat &&
                          oneCategory.id === newsCatsData.data[0].id)
                          ? classes.newsHeaderSelected
                          : ''
                      }`}
                    >
                      {oneCategory.name}
                    </Box>
                  ),
              )}
            </Box>
            <DisplayAllPosts cat={chosenCat || newsCatsData.data[0].id} />
          </>
        ) : (
          <Loader />
        )}
      </>
    );
  }

  function DisplayLastPost() {
    const POSTS_API_URL = `${process.env.REACT_APP_API_BASE}/posts?filter[orderby]=date&order=asc`;
    const postsData = UseDataApi(POSTS_API_URL);

    return (
      <>
        {postsData.error ? (
          <Error />
        ) : postsData.load ? (
          <Grid container>
            <Grid xs={12} md={6} item>
              <img
                src={postsData.data[0].featuredImgUrl}
                className={classes.imgFluid}
                alt={postsData.data[0].title.rendered}
              />
            </Grid>
            <Grid xs={12} md={6} item container alignItems="center">
              <Box p={6}>
                <Box py={2}>
                  <Typography variant="caption">
                    {moment(postsData.data[0].date).format('Do MMM YYYY')}
                  </Typography>
                </Box>
                <Typography
                  variant="h2"
                  dangerouslySetInnerHTML={{
                    __html: postsData.data[0].title.rendered,
                  }}
                />
                <Box
                  pt={2}
                  dangerouslySetInnerHTML={{
                    __html: postsData.data[0].shortText,
                  }}
                />
                <Box pt={3}>
                  <NavLink
                    to={`/news/${postsData.data[0].slug}`}
                    className={classes.aButtonBrown}
                  >
                    Continue reading
                  </NavLink>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Loader />
        )}
      </>
    );
  }

  function DisplayAllPosts(postListProps) {
    const PER_PAGE = 5;

    const [page, setPage] = React.useState(1);
    const [posts, setPosts] = React.useState([]);
    const [moreToLoad, setMoreToLoad] = React.useState(false);

    const { cat } = postListProps;
    const POSTS_API_URL = `${process.env.REACT_APP_API_BASE}/posts?filter[orderby]=date&order=asc&categories=${cat}&per_page=${PER_PAGE}&page=${page}`;
    const postsData = UseDataApi(POSTS_API_URL);

    React.useEffect(() => {
      setPage(1);
      setPosts([]);
    }, [cat]);

    React.useEffect(() => {
      if (postsData.load) {
        setMoreToLoad(postsData.headers['x-wp-totalpages'] != page);
        setPosts(posts.concat(postsData.data));
      }
    }, [postsData.load]);

    return (
      <>
        <Box p={6}>
          <Grid
            container
            className={classes.catsContainer}
            alignItems="stretch"
            justify="flex-start"
          >
            {posts.map((onePost, index) => (
              <PostCard post={onePost} route="/news" showDate />
            ))}
          </Grid>
        </Box>
        {postsData.error ? (
          <Error />
        ) : postsData.load ? (
          <>
            {moreToLoad && (
              <Box textAlign="center">
                <button
                  onClick={() => {
                    setPage(page + 1);
                  }}
                  className={classes.aButtonBrown}
                >
                  Show more
                </button>
              </Box>
            )}
          </>
        ) : (
          <Loader />
        )}
      </>
    );
  }

  return (
    <>
      {postSlug ? (
        <DisplayOnePost />
      ) : (
        <>
          <DisplayLastPost />
          <DisplayCategories />
        </>
      )}
      <Box p={6} />
    </>
  );
}

export default withRouter(withStyles(styles)(NewsPage));
