import { useEffect, useState } from 'react';
import axios from 'axios';

const useDataApi = (url, slug) => {
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  let axiosData = {};

  useEffect(() => {
    let isMounted = true;
    setLoad(false);
    axios
      .get(url)
      .then((res) => {
        if (isMounted) {
          let resData = res.data;
          if (slug === 'products' || slug === 'factory-shop') {
              resData = resData.filter((d) => d.webStatus.toLowerCase() !== 'historical products');
          } else if (slug === 'historical-products') {
            resData = resData.filter((d) => d.webStatus.toLowerCase() === 'historical products');
          }
          setData(resData);
          setHeaders(res.headers);
          setLoad(true);
        }
      })
      .catch((err) => {
        if (isMounted) {
          console.log(err.message);
          setError(true);
          setLoad(true);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [url]);

  axiosData = { data, headers, load, error };
  return axiosData;
};

export default useDataApi;
