import React from 'react';
import { NavLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core';
import { WishlistContext } from '@/context/wishlist-num-context';
import styles from './WishlistNumStyles';

function WishlistNum(props) {
  const { classes } = props;

  const closeAllSlides = props.closeAllSlides ? props.closeAllSlides : () => {};

  const { wishNum } = React.useContext(WishlistContext);

  return (
    <NavLink to="/wishlist/" className={props.class} onClick={closeAllSlides}>
      Wishlist
      <span className={classes.numRound}>{wishNum}</span>
    </NavLink>
  );
}

export default withStyles(styles)(WishlistNum);
