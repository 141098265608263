import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { withStyles, Grid, Box } from '@material-ui/core';
import styles from './ProdCatSwitchStyles';

function ProdCatSwitch(props) {
  const { classes, urlBase, catUrlBase } = props;

  let current = [];
  current = props.match.url.split('/');
  current = current[1];

  let url = 'products';
  if (urlBase) {
    url = urlBase;
  }

  let catUrl = 'categories';
  if (catUrlBase) {
    catUrl = catUrlBase;
  }

  return (
    <Grid container className={classes.switchContainer}>
      <Grid item xs={6}>
        <NavLink
          to={`/${catUrl}/${props.catSlug}`}
          className={`${classes.aNone} ${
            current === catUrl && classes.navLinkSelected
          }`}
        >
          <Box
            className={`${classes.switchBox} ${
              current === catUrl && classes.switchBoxSelected
            }`}
          >
            {props.catName}
            {' '}
            Categories
          </Box>
        </NavLink>
      </Grid>
      <Grid item xs={6}>
        <NavLink
          to={`/${url}/${props.catSlug}`}
          className={`${classes.aNone} ${
            current === url && classes.navLinkSelected
          }`}
        >
          <Box
            className={`${classes.switchBox} ${
              current === url && classes.switchBoxSelected
            }`}
          >
            ALL
            {' '}
            {props.catName}
            {' '}
            Products
          </Box>
        </NavLink>
      </Grid>
    </Grid>
  );
}

export default withRouter(withStyles(styles)(ProdCatSwitch));
