import React from 'react';

// theme & CSS
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import '@/assets/css/bootstrap5.css';

// Header, Nav, Footer
import Navigation from '@/components/layout/Navigation';
import Footer from '@/components/layout/Footer';

// Router
import { BrowserRouter as Router } from 'react-router-dom';

// Hook to scroll page to top
import ScrollToTop from '@/hooks/ScrollTop';

import { UserContextProvider } from '@/context/user-context';
import { NavigationContextProvider } from '@/context/navigation-context';
import { CategoriesContextProvider } from '@/context/categories-context';
import { CollectionsContextProvider } from '@/context/collections-context';
import { WishlistContextProvider } from '@/context/wishlist-num-context';
import { ComparelistContextProvider } from '@/context/comparelist-num-context';
import MainRoutes from './routes';
import theme from './theme';
import HistoricalProductsWarning from './components/HistoricalProductsWarning';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserContextProvider>
        <NavigationContextProvider>
          <CategoriesContextProvider>
            <CollectionsContextProvider>
              <WishlistContextProvider>
                <ComparelistContextProvider>
                  <Router>
                    <ScrollToTop />
                    <div style={{ maxWidth: '2200px', margin: '0 auto' }}>
                      <Navigation />
                      <MainRoutes />
                      <HistoricalProductsWarning />
                      <Footer />
                    </div>
                  </Router>
                </ComparelistContextProvider>
              </WishlistContextProvider>
            </CollectionsContextProvider>
          </CategoriesContextProvider>
        </NavigationContextProvider>
      </UserContextProvider>
    </ThemeProvider>
  );
}

export default App;
