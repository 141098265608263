import React from 'react';
import { withStyles, Grid } from '@material-ui/core';

import ProductCard from '@/components/products/ProductCard';

import FourtyFour from '@/components/FourtyFour';
import styles from './ProductsListStyles.js';

function ProductsList(props) {
  const { classes, productsData, currentCat } = props;
  let urlBase = 'products';
  if (props.urlBase) {
    urlBase = props.urlBase;
  }

  // GTM
  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: currentCat,
      item_list_name: currentCat,
      items:
        productsData && productsData.length > 0
          ? productsData.map((product, i) => ({
              item_id: product.code,
              item_name: product.title,
              affiliation: 'Aquatica online store',
              index: i,
              item_brand: product.range,
              item_category: currentCat,
              item_list_id: currentCat,
              item_list_name: currentCat,
              price: product.price,
            }))
          : [],
    },
  });

  return (
    <Grid
      container
      className={classes.catsContainer}
      alignItems="stretch"
      justify="flex-start"
    >
      {productsData.length > 0
        ? productsData.map((product, index) => (
            <ProductCard
              product={product}
              urlBase={urlBase}
              currentCat={currentCat}
              key={`mainPageProduct${index}`}
            />
          ))
        : !productsData.load && (
            <FourtyFour msg="No products in this category" />
          )}
    </Grid>
  );
}

export default withStyles(styles)(ProductsList);
