import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { withStyles, Grid } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { CategoriesContext } from '@/context/categories-context';
import {
  CatMmBgImageContextProvider,
  CatMmBgImageContext,
} from '@/context/categories-mm-bgimage-context';
import Loader from '@/components/Loader';
import Error from '@/components/Error';
import UseDataApi from '@/hooks/UseDataApi';
import HistoricalProductsWarning from '@/components/HistoricalProductsWarning';
import styles from './MegamenuStyles';

function HistoricalMenu(props) {
  const { classes } = props;

  const mmData = React.useContext(CategoriesContext);

  const url = `${
    process.env.REACT_APP_API_BASE + process.env.REACT_APP_API_PRODUCTS
  }?cat=0`;
  const productsData = UseDataApi(url, 'historical-products');
  const catSlugs = productsData.data.map((p) => p.catTopParentId);
  const availableCats = [...new Set(catSlugs)];

  // third level items
  function MegaCollapsible(props) {
    const [checked, setChecked] = React.useState(false);
    const handleChange = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setChecked((prev) => !prev);
    };

    const { updateBgImage } = React.useContext(CatMmBgImageContext);
    const findUpdateBgImage = (menuItemSLUG) => {
      updateBgImage(menuItemSLUG);
    };

    return (
      <div>
        {mmData.data[props.data.id] ? (
          // if has subsubmenu
          <>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs={8}>
                <NavLink
                  to={`/historical-products-categories/${props.data.slug}`}
                  className={classes.mmItem}
                  onMouseOver={() => findUpdateBgImage(props.data.title)}
                >
                  {props.data.title}
                </NavLink>
              </Grid>
              <a href="/#" className={classes.mmItem} onClick={handleChange}>
                <Grid item xs={4} className={classes.arrowContainer}>
                  {checked ? (
                    <span className={classes.arrow}>&#9652;</span>
                  ) : (
                    <span className={classes.arrow}>&#9662;</span>
                  )}
                </Grid>
              </a>
            </Grid>
            <Collapse in={checked} timeout={700}>
              <div className={classes.mmSubContainer}>
                {mmData.data[props.data.id].map((mmSubSub, index) => (
                  <NavLink
                    to={`/historical-products/${mmSubSub.slug}`}
                    key={`mm-subsubitem-${index}`}
                    className={classes.mmItem}
                    onMouseOver={() => findUpdateBgImage(mmSubSub.title)}
                  >
                    {mmSubSub.title}
                  </NavLink>
                ))}
                <NavLink
                  to={`/historical-products/${props.data.slug}`}
                  className={classes.mmViewAll}
                >
                  ALL
                  {' '}
                  <span className={classes.darker}>{props.data.title}</span>
                  {' '}
                  PRODUCTS
                </NavLink>
              </div>
            </Collapse>
          </>
        ) : (
          // if no subsubmenu
          <NavLink
            to={`/historical-products/${props.data.slug}`}
            className={classes.mmItem}
            onMouseOver={() => findUpdateBgImage(props.data.title)}
          >
            {props.data.title}
          </NavLink>
        )}
      </div>
    );
  }

  // first and second level items
  function MegamenuItems(props) {
    return (
      <ClickAwayListener onClickAway={props.closeAllSlides}>
        <Grid
          item
          xs={9}
          container
          className={classes.mmContainer}
        >
          {mmData.data[0].filter((d) => availableCats.includes(d.id)).map((mmTop, index) => (
            <Grid item key={`mm-column-${index}`} className={classes.mmColumn}>
              <NavLink
                to={`/historical-products-categories/${mmTop.slug}`}
                className={classes.topLvlItem}
              >
                {mmTop.title}
              </NavLink>
              {mmData.data[mmTop.id]
                ? mmData.data[mmTop.id].map((mmSub, index2) => (
                  <MegaCollapsible
                    data={mmSub}
                    key={`mm-collapsible-${index2}`}
                  />
                  ))
                : ''}
              <NavLink
                to={`/historical-products/${mmTop.slug}`}
                className={classes.mmViewAll}
              >
                ALL
                {' '}
                <span className={classes.darker}>{mmTop.title}</span>
                {' '}
                PRODUCTS
              </NavLink>
            </Grid>
          ))}

             <Grid item xs={12}>
                <NavLink to="/historical-products/" className={`${classes.allCollections}`}>
                    All Historical Products
                </NavLink>
            </Grid>

            <Grid item xs={12}>
               <HistoricalProductsWarning />
            </Grid>
        </Grid>
      </ClickAwayListener>
    );
  }

  function BgImageComponent() {
    const { bgImage, updateBgImage } = React.useContext(CatMmBgImageContext);

    return (
      <Grid
        item
        xs={12}
        md={3}
        className={classes.imgBG}
        style={{
          backgroundImage: `url(${bgImage
            .replace('(', '%28')
            .replace(')', '%29')})`,
        }}
        onMouseOver={() => updateBgImage('default')}
      />
    );
  }

  return mmData.error ? (
    <Error />
  ) : mmData.load ? (
    <Grid container className={classes.root}>
      <span className={classes.closeBtn} onClick={props.closeAllSlides}>
        &#215;
      </span>
      <CatMmBgImageContextProvider>
        <BgImageComponent />
        <MegamenuItems closeAllSlides={props.closeAllSlides} />
      </CatMmBgImageContextProvider>
    </Grid>
  ) : (
    <Loader />
  );
}

export default withRouter(withStyles(styles)(HistoricalMenu));
