const pageStyles = (theme) => ({
  imgFluid: { ...theme.mixins.imgFluid },
  imgSuperFluid: { ...theme.mixins.imgSuperFluid },
  flexGrow: {
    flexGrow: 1,
  },
  aNone: { ...theme.mixins.aNone },
});

export default pageStyles;
