import React from 'react';

import { withStyles, Grid, Typography, Box, Hidden } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Flippy, { FrontSide, BackSide } from 'react-flippy';

import '@/assets/css/carousel.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import UseDataApi from '@/hooks/UseDataApi';
import Loader from '@/components/Loader';
import Error from '@/components/Error';
import styles from './AboutStyles.js';

function About(props) {
  const { classes } = props;

  const PAGE_API_URL = `${
    process.env.REACT_APP_API_BASE + process.env.REACT_APP_API_CUSTOMPAGES
  }?page=about-us`;
  const pageData = UseDataApi(PAGE_API_URL);

  const TEAM_API_URL =
    process.env.REACT_APP_API_BASE + process.env.REACT_APP_API_TEAM;
  const teamData = UseDataApi(TEAM_API_URL);

  // config carousel for featured images
  const carouselConfig = () => ({
    showThumbs: false,
    showArrows: true,
    showStatus: false,
    infiniteLoop: true,
    autoPlay: true,
    swipeable: true,
    interval: 3000,
    transitionTime: 700,
    dynamicHeight: false,
    stopOnHover: true,
    showIndicators: false,
  });

  return (
    <>
      {pageData.error ? (
        <Error />
      ) : pageData.load ? (
        <>
          <div className={classes.carouselContainer}>
            <Carousel {...carouselConfig()}>
              {pageData.data.featuredImgs.map((featuredImg, index) => (
                <div key={index}>
                  <img src={featuredImg.full} alt={`About Us ${index}`} />
                </div>
              ))}
            </Carousel>
          </div>

          <Grid container>
            <Grid item xs={12} md={6} container alignItems="center">
              <Typography variant="h1" component="div">
                <Box
                  p={8}
                  dangerouslySetInnerHTML={{ __html: pageData.data.headerText }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" component="div">
                <Box
                  p={8}
                  dangerouslySetInnerHTML={{ __html: pageData.data.content }}
                />
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <Loader />
      )}
      {teamData.error ? (
        <Error />
      ) : teamData.load ? (
        <>
          <Typography variant="h2" component="h2">
            <Box p={8}>Our Team</Box>
          </Typography>

          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.teamCardsContainer}
          >
            {teamData.data.map((teamMember, index) => (
              <React.Fragment key={`teamMember${index}`}>
                {teamMember.name === 'new-row' ? (
                  <Grid item xs={12}>
                    {' '}
                  </Grid>
                ) : (
                  <Grid>
                    <Hidden smDown>
                      <Flippy
                        flipOnHover // default false
                        flipOnClick // default false
                        flipDirection="horizontal"
                      >
                        <FrontSide>
                          <Card className={classes.cardRoot}>
                            <CardMedia
                              className={classes.cardMedia}
                              image={
                                teamMember.img
                                  ? teamMember.img
                                  : `${process.env.REACT_APP_BASE_URL}/assets/images/product_img_placeholder.png`
                              }
                              title={teamMember.name}
                            />
                            <CardContent>
                              <Typography variant="h5" component="div">
                                {teamMember.name}
                              </Typography>
                              <Box
                                dangerouslySetInnerHTML={{
                                  __html: teamMember.position,
                                }}
                              />
                            </CardContent>
                          </Card>
                        </FrontSide>
                        <BackSide>
                          <Card className={classes.cardRoot}>
                            <CardContent>
                              <Typography
                                variant="h3"
                                dangerouslySetInnerHTML={{
                                  __html: teamMember.name,
                                }}
                              />
                              <Typography
                                variant="body2"
                                dangerouslySetInnerHTML={{
                                  __html: teamMember.contatcs,
                                }}
                              />
                              <Box
                                py={2}
                                dangerouslySetInnerHTML={{
                                  __html: teamMember.text,
                                }}
                              />
                            </CardContent>
                          </Card>
                        </BackSide>
                      </Flippy>
                    </Hidden>
                    <Hidden mdUp>
                      <Box pt={2}>
                        <Card className={classes.cardRootMobile}>
                          <CardMedia
                            className={classes.cardMediaMobile}
                            image={
                              teamMember.img
                                ? teamMember.img
                                : `${process.env.REACT_APP_BASE_URL}/assets/images/product_img_placeholder.png`
                            }
                            title={teamMember.name}
                          />
                          <CardContent>
                            <Typography variant="h5" component="div">
                              {teamMember.name}
                            </Typography>
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: teamMember.position,
                              }}
                            />
                            <Typography
                              variant="body2"
                              dangerouslySetInnerHTML={{
                                __html: teamMember.contatcs,
                              }}
                            />
                            <Box
                              py={2}
                              dangerouslySetInnerHTML={{
                                __html: teamMember.text,
                              }}
                            />
                          </CardContent>
                        </Card>
                      </Box>
                    </Hidden>
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </>
      ) : (
        <Loader />
      )}
      <Box p={6} />
    </>
  );
}

export default withStyles(styles)(About);
