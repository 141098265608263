import React from 'react';
import { Box, withStyles } from '@material-ui/core';
import CartMenu from '@/components/layout/CartMenu';
import styles from './CartStyles';

function Cart(props) {
  const { classes } = props;

  return (
    <>
      <CartMenu />
      <Box px={4} pt={5}>
        <iframe
          className={classes.iframe}
          src={`${process.env.REACT_APP_BASE_URL}/cms/cart/`}
          name="Cart"
          frameBorder="0"
          title="Cart"
        />
      </Box>
    </>
  );
}

export default withStyles(styles)(Cart);
