import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { withStyles, Grid, Typography, Box } from '@material-ui/core';

import UseDataApi from '@/hooks/UseDataApi';
import Loader from '@/components/Loader';
import Error from '@/components/Error';
import FourtyFour from '@/components/FourtyFour';
import PostCard from '@/components/posts/PostCard';
import styles from './TipsStyles.js';

function TipsPage(props) {
  const { classes } = props;

  let postSlug = null;
  if (props.match.params.postSlug) {
    postSlug = encodeURIComponent(props.match.params.postSlug);
  }

  function DisplayOnePost() {
    const POST_API_URL = `${process.env.REACT_APP_API_BASE}/tips?slug=${postSlug}`;
    const postData = UseDataApi(POST_API_URL);
    return (
      <>
        {postData.error ? (
          <Error />
        ) : postData.load ? (
          postData.data.length > 0 ? (
            <>
              <Box px={3} pt={3}>
                <NavLink to="/tips-videos" className={classes.arrow}>
                  &#9666; Back to Tips & Videos &#9666;
                </NavLink>
              </Box>
              <Box p={6}>
                {/* <Box py={2}><Typography variant="caption">{moment(postData.data[0].date).format("Do MMM YYYY")}</Typography></Box> */}
                <Typography
                  variant="h2"
                  dangerouslySetInnerHTML={{
                    __html: postData.data[0].title.rendered,
                  }}
                />
                <Box
                  pt={2}
                  dangerouslySetInnerHTML={{
                    __html: postData.data[0].content.rendered,
                  }}
                />
              </Box>
              <Box px={3} pt={3}>
                <NavLink to="/tips-videos" className={classes.arrow}>
                  &#9666; Back to Tips & Videos &#9666;
                </NavLink>
              </Box>
            </>
          ) : (
            <FourtyFour msg="No Posts" />
          )
        ) : (
          <Loader />
        )}
      </>
    );
  }

  function DisplayAllPosts() {
    const PER_PAGE = 5;

    const [offset, setOffset] = React.useState(1);
    const [posts, setPosts] = React.useState([]);
    const [moreToLoad, setMoreToLoad] = React.useState(false);

    const FIRST_POST_API_URL = `${process.env.REACT_APP_API_BASE}/tips?filter[orderby]=date&order=asc&per_page=1`;
    const POSTS_API_URL = `${process.env.REACT_APP_API_BASE}/tips?filter[orderby]=date&order=asc&per_page=${PER_PAGE}&offset=${offset}`;
    const firstPostData = UseDataApi(FIRST_POST_API_URL);
    const postsData = UseDataApi(POSTS_API_URL);

    React.useEffect(() => {
      if (postsData.load) {
        setMoreToLoad(
          parseInt(postsData.headers['x-wp-total']) > offset + PER_PAGE,
        );
        setPosts(posts.concat(postsData.data));
      }
    }, [postsData.load]);

    return (
      <>
        {firstPostData.error ? (
          <Error />
        ) : firstPostData.load ? (
          <Grid container>
            <Grid xs={12} md={6} item>
              <img
                src={firstPostData.data[0].featuredImgUrl}
                className={classes.imgFluid}
                alt={firstPostData.data[0].title.rendered}
              />
            </Grid>
            <Grid xs={12} md={6} item container alignItems="center">
              <Box p={6}>
                {/* <Box py={2}><Typography variant="caption">{moment(postsData.data[0].date).format("Do MMM YYYY")}</Typography></Box> */}
                <Typography
                  variant="h2"
                  dangerouslySetInnerHTML={{
                    __html: firstPostData.data[0].title.rendered,
                  }}
                />
                <Box
                  pt={2}
                  dangerouslySetInnerHTML={{
                    __html: firstPostData.data[0].shortText,
                  }}
                />
                <Box pt={3}>
                  <NavLink
                    to={`/tips-videos/${firstPostData.data[0].slug}`}
                    className={classes.aButtonBrown}
                  >
                    Continue reading
                  </NavLink>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Loader />
        )}
        <Box p={6}>
          <Box py={3} px={3}>
            <Typography variant="h2">More tips and videos</Typography>
          </Box>
          <Grid
            container
            className={classes.catsContainer}
            alignItems="stretch"
            justify="flex-start"
          >
            {posts.map((onePost, index) => (
              <PostCard post={onePost} route="/tips-videos" showDate={false} />
            ))}
          </Grid>
        </Box>
        {postsData.error ? (
          <Error />
        ) : postsData.load ? (
          <>
            {moreToLoad && (
              <Box textAlign="center">
                <button
                  onClick={() => {
                    setOffset(offset + PER_PAGE);
                  }}
                  className={classes.aButtonBrown}
                >
                  Show more
                </button>
              </Box>
            )}
          </>
        ) : (
          <Loader />
        )}
      </>
    );
  }

  return (
    <>
      {postSlug ? <DisplayOnePost /> : <DisplayAllPosts />}
      <Box p={6} />
    </>
  );
}

export default withRouter(withStyles(styles)(TipsPage));
