import pageStyles from './styles/PageStyles';
import formStyles from './styles/FormStyles';

const styles = (theme) => ({
  ...pageStyles(theme),
  ...formStyles(theme),
  catsContainer: {
    padding: '6px',
    margin: '0',
  },
  arrow: {
    fontSize: '12px !important',
    lineHeight: '16px',
    paddingTop: '0.6em',
    paddingBottom: '0.6em',
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    textTransform: 'uppercase',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.additional.black,
    },
  },
  aButtonBrown: theme.mixins.aButtonBrown,
});

export default styles;
